export default Object.freeze({

    A001: 'Successful signup, please login now.',
    A002: 'Personal details successfully updated.',
    A003: 'Password change successfully updated.',
    
    // Account Alert Messages
    A101: 'Test is not yet started, Refresh after sometime or contact your Institution for more details.',
    A102: 'You can not take this Test, it is already Completed. Please contact your Institution for Test Result.',
    A103: 'You can not take this Test, it is Cancelled. Please contact your Institution.',

    // Attendee Alert messages
    A104: 'Your Test started successfully',
    A105: 'Your Test submitted successfully',
    A106: 'Test Event started successfully.',
    A107: 'Test Event completed successfully.',
    A108: 'Test Event created successfully',
    A109: 'Test created successfully',

    A110: 'Test event details are retrieved successfully',
    A111: 'Test event cancelled successfully',

    A112: 'Test question saved successfully',
    A113: 'Test questions submitted successfully',

    A114: 'Test updated successfully',

    // Practice Exam Alert Messages
    A201: 'Your Exam started successfully',
})