import dayjs from 'dayjs';
import { getSessionData } from '../services/storage-services';
import constants from './constants';
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

const moment = require('moment-mini/moment.min')

export const copyrightText = () =>{
  return '© '+new Date().getFullYear()+' NiruzCorp Software Services LLP, All Rights Reserved.'
}

export const disclaimerText = () =>{
  return 'We are not related to, affiliated with, endorsed or authorized by Amazon, Google, Oracle or HashiCorp. Trademarks, certification & product names are used for reference only and belong to respective companies.'
}

export const getCountryInfo = (timezone) => {
  if(!timezone){
    return undefined;
  }
  const filter = constants.COUNTRY_MAP.filter((item) => item.tz === timezone)
  return filter && filter.length > 0 ? filter[0] : undefined
}

export const getPaymentStatusValue = (code) => {
  const filter = constants.PAYMENT_STS_CODE_ENUM.filter((item) => item.code === code)
  return filter && filter.length > 0 ? filter[0].value : ''
}

export const getSubscriptionValue = (code) => {
  const filter = constants.SUBSCRIPTION_CODE_ENUM.filter((item) => item.code === code)
  return filter && filter.length > 0 ? filter[0].value : ''
}

export const getStatusValue = (code) => {
  const filter = constants.STATUS_CODE_ENUM.filter((item) => item.code === code)
  return filter && filter.length > 0 ? filter[0].value : ''
}

export const getTestTypeValue = (code) => {
  const filter = constants.TEST_TYPES.filter((item) => item.value === code)
  return filter && filter.length > 0 ? filter[0].label : ''
}

export const getPastYearItems = (size) => {
  const currentYear = new Date().getFullYear();
  const yearsList = [];

  for (let year = currentYear-size; year <= currentYear; year++) {
    yearsList.push({label:year,value:year});
  }

  return yearsList;
}

export const getPastAndNextYearItems = (size) => {
  const currentYear = new Date().getFullYear();
  const yearsList = [];

  for (let year = currentYear-size; year <= currentYear+size; year++) {
    yearsList.push({label:year,value:year});
  }

  return yearsList;
}

export const getNextYearItems = (size) => {
  const currentYear = new Date().getFullYear();
  const yearsList = [];

  for (let year = currentYear; year <= currentYear + size; year++) {
    yearsList.push({label:year,value:year});
  }

  return yearsList;
}

export const formatQuestionText = (txt) => {
  if(!txt){
    return txt
  }
  if(txt.includes('<br>')){
    return txt.split('<br>')
  }else{
    const lines = [txt]
    return lines
  }
}

export const isFutureDate = (date) => {
  return getTodayDate().isBefore(date)
}

export const getTodayDate = ()  => {
  return dayjs().utc()
}

export const getCurrentYear = ()  => {
  return getTodayDate().year()
}

export const getCurrentMonth = ()  => {
  return getTodayDate().month() + 1
}

export const getCurrentMonthString = ()  => {
  return getTodayDate().format('MMM')
}

export const getTodayDateString = () =>{
    return getTodayDate().format('YYYY-MM-DD')
}

export const getCurrentDateTime = () =>{
  return getTodayDate().format('YYYY-MM-DD hh:mm:ss A')
}

export const getCurrentTime = () =>{
  return getTodayDate().format('HH:mm')
}

export const convertSecondsToTime = (sec) =>{
  return moment().startOf('day').seconds(sec).format('mm:ss');
}

export const extractYear = (date) =>{
  return dayjs(date).format('YYYY');
}

export const extractMonth = (date) =>{
  return dayjs(date).format('M');
}

export const getAPIDate = (date) =>{
  return dayjs(date).format('YYYY-MM-DD');
}

export const getDisplayDate = (date) =>{
  return dayjs(date, "YYYY-MM-DD").format('ll');
}

export const getDisplayTime = (time) =>{
  if(!time){
    return '';
  }
  const country = getSessionData(constants.SESSION_KEY_COUNTRY)
  if(country && country!=='undefined'){
    return dayjs(time, "HH:mm").utc('z').local().tz(country.tz).format('h:mm A');
  }else{
    return dayjs(time, "HH:mm").format('h:mm A') + ' (GTM)';
  }
}

export const areArraysMatchingUnordered = (arr1, arr2) =>{
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}

export const strContainInArray = (str, arr) =>{
  if (!arr || arr.length === 0 || !str) {
    return false;
  }

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === str) {
      return true;
    }
  }
  return false;
}

export const removeEmptyObjects =(obj) =>{
  for (var prop in obj) {
    if (obj[prop] && typeof obj[prop] === 'object') {
      this.removeEmptyObjects(obj[prop]);
      if (Object.keys(obj[prop]).length === 0) {
        delete obj[prop];
      }
    } else if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '') {
      delete obj[prop];
    }
  }
  return obj;
}

const formatURL = (url, arg) => {
  let formatedURL = arg?.queryParams ? url + '?' + new URLSearchParams(arg.queryParams) : url;
  if(arg?.pathParams){
    for (const [key, value] of Object.entries(arg.pathParams)) {
      formatedURL = formatedURL.replace('{'+key+'}', value)
    }
  }
  return formatedURL;
}

const getHeaders = (arg) =>{
  let headers = {}
  if(arg?.headers){
    headers = arg.headers
  }
  headers['Content-Type'] = "application/json"
  if(getSessionData(constants.SESSION_KEY)){
    headers['Authorization'] = 'Bearer '+JSON.parse(getSessionData(constants.SESSION_KEY)).idToken
    headers['x-session-guid'] = JSON.parse(getSessionData(constants.SESSION_KEY)).id
  }
  return headers
}

export async function getHTTP(url) {
  return fetch(url, {
    method: 'GET'
  }).then(res => res.json())
}

export async function getRequest(url, { arg }) {
  const formatedURL = formatURL(url, arg)
  return fetch(formatedURL, {
    method: 'GET',
    headers: getHeaders(arg)
  }).then(res => res.json())
}

export async function postRequest(url, { arg }) {
    const formatedURL = formatURL(url, arg)
    return fetch(formatedURL, {
      method: 'POST',
      body: JSON.stringify(arg.requestBody),
      headers: getHeaders(arg)
    }).then(res => res.json())
}

export async function putRequest(url, { arg }) {
    const formatedURL = formatURL(url, arg)
    return fetch(formatedURL, {
      method: 'PUT',
      body: JSON.stringify(arg.requestBody),
      headers: getHeaders(arg)
    }).then(res => res.json())
}

export async function deleteRequest(url, { arg }) {
    const formatedURL = formatURL(url, arg)
    return fetch(formatedURL, {
      method: 'DELETE',
      headers: getHeaders(arg)
    }).then(res => res.json())
}
