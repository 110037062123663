import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';

const MUIDateField = (props) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller name={props.name} control={props.control}
            render={({ 
                field: { onChange, onBlur, value, ref}, 
                fieldState: { error } }) => (
                <DatePicker
                    InputLabelProps={{ shrink: true }}
                    slotProps={{
                      textField: {
                        id: props.id,
                        size: 'small',
                        error: !!error,
                        helperText: error ? error.message : null,
                      }
                    }}
                    onChange={onChange} // send value to hook form
                    onBlur={onBlur} // notify when input is touched
                    inputRef={ref} // wire up the input ref
                    format="ll"

                    {...props}/>
            )}>
        </Controller>
      </LocalizationProvider>
    );
};

export default MUIDateField;