import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom';

import PrivateLayout from './components/layout/private/PrivateLayout'
import PublicLayout from './components/layout/public/PublicLayout'

import LandingPage from './pages/LandingPage';
import AttendeeLoginPage from './pages/attendee/LoginPage';
import AttendeeTestPage from './pages/attendee/TestPage';
import AttendeeTestResultPage from './pages/attendee/TestResultPage';

import PracticeExamPage from './pages/practice-exam/PracticeExamPage';
import PracticeExamLoginPage from './pages/practice-exam/LoginPage';

import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/legal/TermsAndConditionsPage';

import InstituteLoginPage from './pages/institute/LoginPage';
import DashboardPage from './pages/institute/DashboardPage';
import InstituteTestBankPage from './pages/institute/test/TestBankPage';
import InstituteTestDetailsPage from './pages/institute/test/TestDetailsPage';
import InstituteTestEventsPage from './pages/institute/test/TestEventsPage';
import InstituteTestEventDetailsPage from './pages/institute/test/TestEventDetailsPage';
import ProfilePage from './pages/institute/account/ProfilePage';
import SubscriptionsPage from './pages/institute/account/SubscriptionsPage';

import { AppContext } from "./AppContext";
import { SWRConfig } from 'swr';
import React from 'react';
import MUISnackBar from './components/widgets/MUISnackBar';
import errors from './util/errors';
import { clearSessionData } from './services/storage-services';
import MyProfilePage from './pages/institute/users/MyProfilePage';

function App() {

  var [headerTitle, setHeaderTitle] = React.useState("");
  var [openModel, setOpenModel] = React.useState({});
  var [pageMode, setPageMode] = React.useState("");
  const [snackBar, setSnackBar] = React.useState({show:false});

  return (
    <AppContext.Provider value={{ headerTitle, setHeaderTitle, pageMode, setPageMode, openModel, setOpenModel }}>
      <MUISnackBar open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
      <SWRConfig value={{
          onError: (error, key) => {
            if (error.status !== 401 && error.status !== 403) {
              // clearSessionData()
              // setSnackBar({show:true,severity:'error',message:errors.E000})
              // window.open("/");
            }
          }}}>
          <BrowserRouter>
              <Routes>
                <Route path="/" element={<PublicLayout />}>
                    <Route index element={<LandingPage />} />
                    <Route path="practice-exam/purchase/success" element={<LandingPage />} />
                    <Route path="practice-exam/purchase/cancel" element={<LandingPage />} />
                    
                    <Route path="practice-exam/attempt/:purchaseId" element={<PracticeExamLoginPage />} />
                    <Route path="practice-exam/attempt" element={<PracticeExamPage />} />

                    <Route path="institute" element={<InstituteLoginPage />} />

                    <Route path="attendee" element={<AttendeeLoginPage />} />
                    <Route path="attendee/test/event" element={<AttendeeTestPage />} />
                    <Route path="attendee/test/event/result" element={<AttendeeTestResultPage />} />

                    <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="terms-and-conditions" element={<TermsAndConditionsPage />} />
                    
                    <Route path="*" element={ <Navigate to="/" /> }/>
                </Route>

                <Route path="/institute" element={<PrivateLayout />}>
                    <Route path="dashboard" element={<DashboardPage />} />

                    <Route path="test/bank" element={<InstituteTestBankPage />} />
                    <Route path="test/bank/:testId" element={<InstituteTestDetailsPage />} />
                    <Route path="test/events" element={<InstituteTestEventsPage />} />
                    <Route path="test/event/:eventId" element={<InstituteTestEventDetailsPage />} />

                    <Route path="user/profile" element={<MyProfilePage />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="subscription" element={<SubscriptionsPage />} />
                </Route>
              </Routes>
          </BrowserRouter>
      </SWRConfig>
    </AppContext.Provider>
  );
}

export default App;
