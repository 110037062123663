import React from 'react';
import { useCreateTest, useDeleteTest, useGetTestBank, useUpdateTest } from '../../../services/testbank-services';
import constants from '../../../util/constants';
import MUIButton from '../../../components/widgets/fields/MUIButton';
import {Alert, FormControl, Grid, Stack, Typography} from '@mui/material';
import Swal from 'sweetalert2';
import { getSessionData, putSessionData } from '../../../services/storage-services';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import MUITestBankTable from '../../../components/widgets/tables/MUITestBankTable';
import { Dialog, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import MUITextField from '../../../components/widgets/fields/MUITextField';
import { getFutureDateValidator, getLocationValidator, getTitleValidator } from '../../../services/validations';
import MUIDateField from '../../../components/widgets/fields/MUIDateField';
import MUISelectField from '../../../components/widgets/fields/MUISelectField';
import MUISnackBar from '../../../components/widgets/MUISnackBar';
import alerts from '../../../util/alerts';

import dayjs from 'dayjs';
import { useCreateTestEvent } from '../../../services/testevent-services';
import { extractMonth, extractYear, getAPIDate, isFutureDate, removeEmptyObjects } from '../../../util/utility';
import { AppContext } from '../../../AppContext';
import errors from '../../../util/errors';

const TestBankPage = () => {
    
    var { setHeaderTitle } = React.useContext(AppContext);

    React.useEffect(() => {
      setHeaderTitle('Test Bank')
    }, []); // Run once

    const [subscription] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_SUBSCRIPTION)));
    const [expiredSubscription, setExpiredSubscription] = React.useState(true); // let buttons be disabled first
    React.useEffect(() => {
        if(isFutureDate(subscription?.edt)){
            setExpiredSubscription(false)
            triggerTestBank()
        }else{
            setExpiredSubscription(true)
        }
    }, [subscription]); // Run once

    const [openTestDialog, setOpenTestDialog] = React.useState(false);
    const [openEventDialog, setOpenEventDialog] = React.useState(false);
    const [selectedTest, setSelectedTest] = React.useState();
    const [testMode, setTestMode] = React.useState('New');
    const [snackBar, setSnackBar] = React.useState({show:false});

    // Form Validations
    const eventSchema = Yup.object().shape({
        ttl: getTitleValidator(),
        loc: getLocationValidator(),
        sdt: getFutureDateValidator()
    });
    const { handleSubmit:validateEventForm, reset:resetEventForm, control:controlEventForm } = useForm({mode:'onTouched', resolver: yupResolver(eventSchema)});   

    const testSchema = Yup.object().shape({
        ttl: getTitleValidator()
    });
    const { handleSubmit:validateTestForm, reset:resetTestForm, control:controlTestForm } = useForm({mode:'onTouched', resolver: yupResolver(testSchema)});   

    // Form data states 
    const {data:testBank, error:testBankError, trigger:triggerTestBank, isMutating:testBankMutating } = useGetTestBank()
    React.useEffect(() => {
        if(testBank){
            putSessionData(constants.SESSION_KEY_TEST_BANK,JSON.stringify(testBank))
        }else if(testBank?.errs && testBank?.errs.length > 0){
            setSnackBar({show:true,severity:'error',message:testBank.errs[0].msg})
        }else if(testBankError){
            setSnackBar({show:true,severity:'error',message:errors.E102})
        }
    }, [testBank]); // Run the effect whenever the data changes

    const handleRefresh = async () => {
        await triggerTestBank()
    }

    const handleNewTest = () => {
        // Verify if the Limit on Number of Test under the Subscribed package is complete
        const sub = JSON.parse(getSessionData(constants.SESSION_KEY_SUBSCRIPTION))?.sub
        var limit = 0
        if(sub === constants.PKGTRAIL && testBank?.length > 9){
            limit = 10
        }else if(sub === constants.PKG10 && testBank?.length > 9){
            limit = 10
        }else if(sub === constants.PKG25 && testBank?.length > 24){
            limit = 24
        }else if(sub === constants.PKG100 && testBank?.length > 99){
            limit = 100
        }

        if(limit > 0){
            Swal.fire({
                target: document.getElementById('edit-questions-dialog'),
                title: 'Subscription Limit', 
                text: 'Your current subscription has a limit of '+limit+' Tests. To create New Test, either upgrade the subscription or delete the unused test.',
                icon: 'info'
            })
        }else{
            setTestMode('New')
            resetTestForm({typ: 'QUIZ'})
            setOpenTestDialog(true)
        }
    }

    const handleEditTest = (selectedTestId) => {
        setTestMode('Edit')
        const filter = testBank.filter((item) => item.id === selectedTestId)
        setSelectedTest(filter[0])
        resetTestForm(filter[0])
        setOpenTestDialog(true)
    }

    const handleCreateEvent = (selectedTestId) => {
        const filter = testBank.filter((item) => item.id === selectedTestId)
        setSelectedTest(filter[0])
        resetEventForm({})
        if(filter[0].qcnt > 0){
            setOpenTestDialog(false)
            setOpenEventDialog(true)
        }else{
            Swal.fire('Error', '0 questions in this test, please add them before creating test event.','error')
        }
    }

    // This will be invoked as needed
    const {data:deleteTestData, error:deleteTestError, trigger:triggerDeleteTest, isMutating:deleteTestMutating} = useDeleteTest()
    const submitDeleteTest = async() => {
        setOpenTestDialog(false)
        Swal.fire({
            title: "Are you sure ?",
            text: "Once deleted, you will not be able to recover this test!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
          })
         .then((willDelete) => {
            if(willDelete.isConfirmed) {
               deleteTest()
            }
         });
    }
    const deleteTest = async() => {
        const arg = { 
            pathParams: { testId: selectedTest.id }
        }
        await triggerDeleteTest(arg)
        if(!deleteTestMutating){
            setOpenTestDialog(false)
            if(!deleteTestError){
                if(deleteTestData?.errs && deleteTestData?.errs.length > 0){
                    Swal.fire('Error!', deleteTestData.errs[0].msg, 'error')
                }else{
                    await triggerTestBank()
                    Swal.fire('Success!', 'Test deleted successfully', 'success')
                }
            }else{
                Swal.fire('Error', 'Unable to delete Test, please retry again.','error')
            }
        }
    }

    // This will be invoked as needed
    const {data:createTestData, error:createTestError, trigger:triggerCreateTest, isMutating:createTestMutating} = useCreateTest()
    const submitCreateTest = async(formData) => {
        const arg = { 
            requestBody: formData
        }
        await triggerCreateTest(arg)
        if(!createTestMutating){
            setOpenTestDialog(false)
            if(!createTestError){
                if(createTestData?.errs && createTestData?.errs.length > 0){
                    setSnackBar({show:true,severity:'error',message:createTestData.errs[0].msg})
                }else{
                    setSnackBar({show:true,severity:'success',message:alerts.A109})
                    // Trigger reload for now
                    triggerTestBank();
                }
            }else{
                Swal.fire('Error', 'Unable to create Test, please retry again.','error')
            }
        }
    }

    // This will be invoked as needed
    const {data:updateTestData, error:updateTestError, trigger:triggerUpdateTest, isMutating:updateTestMutating} = useUpdateTest()
    const submitUpdateTest = async(formData) => {
        const arg = { 
            pathParams: { testId: selectedTest.id },
            requestBody: formData
        }
        await triggerUpdateTest(arg)
        if(!updateTestMutating){
            setOpenTestDialog(false)
            if(!updateTestError){
                if(updateTestData?.errs && updateTestData?.errs.length > 0){
                    setSnackBar({show:true,severity:'error',message:updateTestData.errs[0].msg})
                }else{
                    setSnackBar({show:true,severity:'success',message:alerts.A114})
                    // Trigger reload for now
                    triggerTestBank();
                }
            }else{
                Swal.fire('Error', 'Unable to create Test, please retry again.','error')
            }
        }
    }

    // This will be invoked as needed
    const {data:submitTestEventData, error:submitTestEventError, trigger:triggerSubmitTestEvent, isMutating:submitTestEventMutating} = useCreateTestEvent()
    const submitCreateEvent = async(formData) => {
        const arg = { 
            requestBody: removeEmptyObjects({...formData, typ:selectedTest.typ, sdt:getAPIDate(formData.sdt), tsid:selectedTest.id, 
                             tlmt: selectedTest.tlmt, nscr: selectedTest.nscr}),
            queryParams: { year:extractYear(formData.sdt), month:extractMonth(formData.sdt) } 
        }
        await triggerSubmitTestEvent(arg)
        if(!submitTestEventMutating){
            setOpenEventDialog(false)
            if(!submitTestEventError){
                if(submitTestEventData?.errs && submitTestEventData?.errs.length > 0){
                    setSnackBar({show:true,severity:'error',message:submitTestEventData.errs[0].msg})
                }else{
                    setSnackBar({show:true,severity:'success',message:alerts.A108})
                }
            }else{
                Swal.fire('Error', 'Unable to create Test Event, please retry again.','error')
            }
        }
    }

    return (        
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
            <MUISnackBar id="institute-testbank-bar" open={snackBar?.show} message={snackBar?.message} severity={snackBar?.severity} onClose={()=>setSnackBar({show:false})}/>
            <Stack direction="column" spacing={1} sx={{ width: 0.95 }}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>  
                    
                    {!isFutureDate(subscription?.edt) && 
                        <Grid item xs={4} sm={8} md={9}>
                            <Alert id="subscription-heading-id" variant="filled" severity="error">Your current subscription is Expired. Please renew by purchasing new subscription.</Alert>
                        </Grid>   
                    }
                    
                    <Grid item xs={4} sm={8} md={10} marginTop={3}>
                        <Stack direction="row" spacing={2}>
                            <MUIButton id="institute-testbank-reload" loading={testBankMutating} disabled={expiredSubscription} label="Reload" type="info" variant="outlined" onClick={handleRefresh}/>
                            <MUIButton id="institute-newtest-btn" label="New Test" type="info" disabled={expiredSubscription} variant="outlined" onClick={handleNewTest} />
                        </Stack>
                    </Grid>
                    
                    <Grid item xs={4} sm={8} md={10}>
                        <MUITestBankTable data={testBank} onEditTest={handleEditTest} onCreateEvent={handleCreateEvent}/>
                    </Grid>

                    <Dialog open={openTestDialog}>
                        <DialogTitle sx={{ m: 0, p: 2 }}>
                            <Typography variant='h5' fontSize={24}>{testMode === 'New' ? 'Create Test' : 'Edit Test'}</Typography>
                            <IconButton id="institute-newtest-close" onClick={() => {setOpenTestDialog(false)}} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText marginBottom={3}>
                                { testMode === 'New' ? 'To create new Test, please fill below details and submit.' : 'To update Test details, please update below and submit.' }
                            </DialogContentText>
                            <FormControl>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                                    <Grid item xs={4} sm={8} md={10}>
                                        <MUITextField id="institute-newtest-ttl" name="ttl" label="Test Title" control={controlTestForm} required maxLength={81}/> 
                                    </Grid>
                                    <Grid item xs={4} sm={8} md={5}>
                                        <MUISelectField id="institute-newtest-typ" name="typ" label='Test Type' control={controlTestForm} options={constants.TEST_TYPES} />
                                    </Grid>
                                    {
                                        
                                    }
                                    <Grid item xs={4} sm={8} md={10}>
                                        <Stack direction="row" spacing={2}>
                                            {testMode === 'New' ? <MUIButton id="institute-newtest-submit" loading={submitTestEventMutating} label="Create" type="secondary" onClick={validateTestForm(submitCreateTest)} /> : ''}
                                            {testMode === 'Edit' ? <MUIButton id="institute-edittest-submit" label="Update" type="secondary" onClick={validateTestForm(submitUpdateTest)} /> : ''}
                                            {testMode === 'Edit' ? <MUIButton id="institute-deletetest-submit" label="Delete" type="error" onClick={validateTestForm(submitDeleteTest)} /> : ''}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={openEventDialog}>
                        <DialogTitle sx={{ m: 0, p: 2 }}>
                            <Typography variant='h5' fontSize={24}>Create Test Event</Typography>
                            <IconButton id="testevent-close-btn" onClick={() => {setOpenEventDialog(false)}} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText marginBottom={3}>
                                To create new Test Event, please review below details and submit.
                            </DialogContentText>
                            <FormControl>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>     
                                    <Grid item xs={4} sm={8} md={10} marginBottom={2}>
                                        <Stack direction="column" spacing={1}>
                                            <Typography>Test Title: {selectedTest?.ttl}</Typography>
                                            {selectedTest?.tlmt? <Typography>Time Limit: {selectedTest.tlmt} minutes </Typography> : ''}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} sm={8} md={10}>
                                        <MUITextField name="ttl" id="testevent-ttl" label="Event Title" control={controlEventForm} required maxLength={81}/>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={5}>
                                        <MUITextField name="loc" id="testevent-loc" label="Event Location" control={controlEventForm} required maxLength={41}/>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={5}>
                                        <MUIDateField name="sdt" id="testevent-sdt" label="Schedule Date" control={controlEventForm} minDate={dayjs()} required/>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={10}>
                                        <MUIButton id="testevent-create-btn" loading={submitTestEventMutating} onClick={validateEventForm(submitCreateEvent)} label="Create" type="secondary" />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Stack>
        </Grid>
    )
}

export default TestBankPage;