import React from 'react';
import { useGetTestEvents } from '../../../services/testevent-services';
import constants from '../../../util/constants';
import MUIButton from '../../../components/widgets/fields/MUIButton';
import { Alert, Grid, Stack } from '@mui/material';
import { getCurrentMonth, getCurrentYear, getPastAndNextYearItems, isFutureDate } from '../../../util/utility';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import MUITestEventsTable from '../../../components/widgets/tables/MUITestEventsTable';
import MUISelectField from '../../../components/widgets/fields/MUISelectField';
import { AppContext } from '../../../AppContext';
import { getSessionData } from '../../../services/storage-services';

const TestEventsPage = () => {
    const { setHeaderTitle } = React.useContext(AppContext);
    React.useEffect(() => {
      setHeaderTitle('Test Events')
    }, []); // Run once

    const [subscription] = React.useState(JSON.parse(getSessionData(constants.SESSION_KEY_SUBSCRIPTION)));
    const [expiredSubscription, setExpiredSubscription] = React.useState(true); // let buttons be disabled first
    React.useEffect(() => {
        resetEventSearch({year:getCurrentYear(), month:getCurrentMonth()})
        if(isFutureDate(subscription?.edt)){
            setExpiredSubscription(false)
            triggerGetTestEvents({queryParams: { year:getCurrentYear(), month:getCurrentMonth() }})
        }else{
            setExpiredSubscription(true)
        }
    }, [subscription]); // Run once

    const eventSearchSchema = Yup.object().shape({
        // TBD
    });
    const { handleSubmit:validateEventSearch, reset:resetEventSearch, control:controlEventSearch } = useForm({mode:'onTouched', resolver: yupResolver(eventSearchSchema)});   

    const {data:testEvents, error:testEventsError, trigger:triggerGetTestEvents, isMutating:testEventsMutating } = useGetTestEvents()
    const handleRefreshResults = async (formData) => {
        await triggerGetTestEvents({queryParams: { year:formData.year, month:formData.month }})
    }

    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
            <Stack direction="column" spacing={1} sx={{ width: 0.95}}>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                    {!isFutureDate(subscription?.edt) && 
                        <Grid item xs={4} sm={8} md={9}>
                            <Alert id="subscription-heading-id" variant="filled" severity="error">Your current subscription is Expired. Please renew by purchasing new subscription.</Alert>
                        </Grid>   
                    }

                    <Grid item xs={4} sm={2} md={3} marginTop={3}>
                        <Stack direction="row" spacing={2}>
                            <MUISelectField id="institute-testevent-year" name="year" label='Year' disabled={expiredSubscription} control={controlEventSearch} options={getPastAndNextYearItems(1)} />
                            <MUISelectField id="institute-testevent-month" name="month" label='Month' disabled={expiredSubscription} control={controlEventSearch} options={constants.CALENDAR_MONTHS_LIST}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={4} sm={6} md={7} marginTop={3}>
                        <MUIButton loading={testEventsMutating} id="institute-testevent-reload" disabled={expiredSubscription} label="Reload" type="info" variant="outlined" size="medium" onClick={validateEventSearch(handleRefreshResults)}/>
                    </Grid>
                    
                    <Grid item xs={4} sm={8} md={10}>
                        <MUITestEventsTable data={testEvents} />
                    </Grid>
                </Grid>
            </Stack>
        </Grid>
    )

}

export default TestEventsPage;